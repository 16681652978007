<template>
  <div 
    ref="sizeRecommendRef" 
    class="recommendEntrance-size"
  >
    <!-- 第三方size推荐 -->
    <template v-if="sizeRecommendType == 2 && !sizeGuidAlone">
      <!-- 第三方size推荐 -->
      <div 
        class="j-sa-check-size j-btn-check-my-size-wrapper"
      ></div>
    </template>
  
    <!-- 自己的推荐 -->
    <a
      v-if="showSelfRecommend"
      class="link-check-my-size"
      :class="{ 'link-check-my-size-planA': true }"
      @click="selfSizeRecommendClick"
    >
      <p>
        <template v-if="recommendMySizeText">
          <b>
            “{{ recommendMySizeText }}”
          </b>
          {{ language.SHEIN_KEY_PWA_18535 }}
        </template>
        <template v-else>
          {{ language.SHEIN_KEY_PWA_18113 }}
        </template>
      </p>
      <span class="icon suiiconfont sui_icon_more_right_12px_2"></span>
    </a>
    <!-- Sizeguide新入口 -->
    <a
      v-if="showSizeGuideEntry"
      v-expose="{ id: '1-6-1-137', data: { from: isFromPopup ? 2 : 1 } }"
      state="sizeGuideSizeEntry"
      class="link-check-my-size j-push-history-hash"
      :class="{ 'link-check-my-size-planA': true, 'size-guide-entry-alone': sizeGuidAlone }"
      @click="handleSizeGuide"
    >
      <p>
        {{ language.SHEIN_KEY_PWA_15005 }}
      </p>
      <span class="icon suiiconfont sui_icon_more_right_12px_2"></span>
    </a>
  </div>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { daEventCenter } from 'public/src/services/eventCenter/index'

export default defineComponent({
  directives: {
    expose,
  },
  emits: [
    'handleSizeRecommendType',
    'handleSizeRecommend',
    'handleSizeGuide',
  ],
  props: {
    // sizeGuidPlanA: {
    //   type: Boolean,
    //   default: false
    // },
    sizeGuidAlone: {
      type: Boolean,
      default: false
    },
    currentGoodsId: {
      type: String,
      default: ''
    },
    localSizeRule: {
      type: Array,
      default: () => []
    },
    recommendMySize: {
      type: String,
      default: ''
    },
    recommendMySizeText: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default: () => ({})
    },
    ruleId: {
      type: [String, Number],
      default: ''
    },
    ruleType: {
      type: [String, Number],
      default: ''
    },
    country: {
      type: String,
      default: ''
    },
    recommendMyBraSize: {
      type: String,
      default: ''
    },
    showNewSizeGuide: {
      type: Boolean,
      default: false
    },
    saleAttrList: {
      type: Object,
      default: () => {}
    },
    isNewSizeLocal: {
      type: Boolean,
      default: false
    },
    isFromPopup: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      sizeRecommendType: 0, // 0 不展示 1 自己的尺码推荐 2 第三方尺码推荐
      time: null
    }
  },
  computed: {
    showSelfRecommend(){
      return this.sizeRecommendType == 1 && !this.isOneSize && !this.sizeGuidAlone
    },
    /* 
      todo: 后期收拢
        1. sizeRecommendType：1-自有checkMySize 2-第三方checkMySize 0-sizeguide
        2. 命中尺码折叠场景（showSaleAttrFold=true），自有checkMySize 和 sizeguide 一定是分开显示
        3. 正常场景（尺码非折叠场景），sizeGuidAlone=true（checkMySize & sizeguide分开两行展示）
        4. 正常场景（尺码非折叠场景），sizeGuidAlone=false（checkMySize & sizeguide合并展示），优先展示自有checkMySize，其次展示第三方checkMySize（第三方checkMySize加载失败则展示sizeguide），最后展示sizeguide
    */
    showSizeGuideEntry() {
      return this.showNewSizeGuide && (this.sizeGuidAlone || (!this.sizeGuidAlone && this.sizeRecommendType == 0) || (this.isOneSize && this.sizeRecommendType !== 2))
    },
    // recommendMySizeText() {
    //   // 0 鞋子 1内衣
    //   if(this.ruleType == 1){
    //     return this.recommendMyBraSize
    //   }
    //   if (!this.country) {
    //     return this.recommendMySize
    //   }
    //   const find = this.localSizeRule.find(el => el.name == this.recommendMySize)?.correspond || ''
    //   const { isNewSizeLocal } = this
    //   if (find) {
    //     if (isNewSizeLocal) {
    //       return `${find}(${this.recommendMySize})`
    //     } else {
    //       return `${this.recommendMySize}(${find})`
    //     }
    //   }
    //   return `${this.recommendMySize}`
    // },
    isOneSize() {
      const skcSaleAttr = this.saleAttrList?.skc_sale_attr ?? []
      const sizeAttr = skcSaleAttr?.find(item => {
        return item.attr_id == 87
      })
      return sizeAttr?.attr_value_list?.some(item => item.attr_value_name === 'one-size')
    }
  },
  watch: {
    ruleId: {
      immediate: true,
      handler (val) {
        if (val !== '') {
          nextTick(async () => {
            const { Selfsizerecommended: { param } = {}, } = await getUserAbtData()
            if (param === 'type=C') return
            if (this.ruleId != 0) {
              this.sizeRecommendType = 1
            } else {
              // this.sizeRecommendType = 2
              // 强制下线第三方脚本
              this.sizeRecommendType = 0
            }

            // RW不接入第三方推荐
            // if (this.sizeRecommendType === 2) {
            //   this.sizeRecommendType = 0
            // }

            this.$emit('handleSizeRecommendType', this.sizeRecommendType)
            // 这块埋点是有问题的,产品要求的是第三方<有无数据返回>而非<站点限制>来决定上报的method值,但是江纯说以后再改
            // 本此改动仅是代码等效转换 & 放出ROMWE
            // const loadThirdRecommend = window.TPM?.marketings?.Fitanalytics?.active // 判断有没有家在第三方需要加载
            // this.sizeRecommendReport(loadThirdRecommend) 
            if (this.sizeRecommendType == 1 && !this.sizeGuidAlone) {
              this.exposeSizeRecommend('self')
              return
            }

            if (this.sizeRecommendType !== 2) return

            const { message = '' } = await window.TPM?.runImmediate?.({
              marketing: 'Fitanalytics',
              method: '_loadPdp',
            })
            if (message == 'success') {
              this.time && clearTimeout(this.time)
              this.time = setTimeout(() => {
                const hasThirdDom = () => {
                  if (typeof window === 'undefined') return false
                  const fitanalyticsButton = document?.getElementsByClassName('fitanalytics__button')?.[0]
                  return fitanalyticsButton && fitanalyticsButton?.style?.display !== 'none'
                }
                if (hasThirdDom()) { // 判断第三方有没有成功加载成功
                  this.exposeSizeRecommend('third_party') 
                } else if (this.showNewSizeGuide){ // 判断第三方有没有加载成功,没有加载成功就展示新的sizeguide
                  const config = { attributes: true, childList: true, subtree: true }
                  const observer = new MutationObserver(() => {
                    if(hasThirdDom()) {
                      const itanalyticsBtnDom = document.getElementsByClassName('fitanalytics__button') ?? []
                      itanalyticsBtnDom?.length && (itanalyticsBtnDom[0].style.display = 'none')
                      observer.disconnect()
                    }
                  })
                  const targetElement = this.$refs.sizeRecommendRef
                  observer.observe(targetElement, config)
                  this.sizeRecommendType = 0
                  this.$emit('handleSizeRecommendType', this.sizeRecommendType)
                }
              }, 3000)
            } else {
              this.sizeRecommendType = 0 
              this.$emit('handleSizeRecommendType', this.sizeRecommendType)
            }
          })
        } else {
          this.sizeRecommendType = -1
          this.$emit('handleSizeRecommendType', this.sizeRecommendType)
        }
      }
    }
  },
  mounted () {
    this.traceCheckSize()
  },
  unmounted() {
    clearTimeout(this.time)
  },
  methods: {
    exposeSizeRecommend(method) {
      if(!method) return
      daEventCenter.triggerNotice({
        daId: '1-6-1-43',
        extraData: {
          goods_id: this.currentGoodsId,
          method,
        }
      })
    },
    delegateEvent(eventType, selector, handler) {
      // 返回一个函数，该函数检查触发事件的元素是否匹配指定的选择器
      return function(event) {
        if (event.target.matches(selector) || event.target.closest(selector)) {
          handler(event)
        }
      }
    },
    selfSizeRecommendClick() {
      this.$emit('handleSizeRecommend')
      this.reportSizeBi()
    },
    handleSizeGuide() {
      this.$emit('handleSizeGuide', 'sizeGuideSizeEntry', this.isFromPopup ? 'cart_title' : 'detail_title', this.isFromPopup ? 2 : 1)
    },
    traceCheckSize () {
      const touchstartHandler = () => timer = Date.now()
      const touchendHandler = () => {
        if (Date.now() - timer <= 300) {
          this.reportSizeBi()
        }
        timer = -1
      }
      // 无法监听到第三方的点击事件
      let timer = -1
      const touchEleClass = '.fitanalytics__button'
      // 定义要委托的事件处理器
      const delegatedTouchStartHandler = this.delegateEvent('touchstart', touchEleClass, touchstartHandler)
      const delegatedTouchEndHandler = this.delegateEvent('touchend', touchEleClass, touchendHandler)
      // 添加委托的事件监听器
      document.addEventListener('touchstart', delegatedTouchStartHandler)
      document.addEventListener('touchend', delegatedTouchEndHandler)
    },
    reportSizeBi() {
      const method = this.sizeRecommendType == 1 ? 'self' : 'third_party'
      daEventCenter.triggerNotice({
        daId: '1-6-1-44',
        extraData: {
          goods_id: this.currentGoodsId,
          method,
        }
      })
    },
  },
})
</script>
<style lang="less">
.recommendEntrance-size{
  display: flex;
  flex:1;
  justify-content: flex-end;
  overflow: hidden;
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.link-check-my-size {
  display: inline-flex;
  align-items: center;
  cursor: auto;
  width: auto;
  margin-left: .13rem;
  margin-bottom: 0.1.7rem;
  text-decoration-line: none;
  line-height: normal;
  overflow: hidden;
  p {
    max-width: 3.22667rem;
    .text-overflow();
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
  .icon {
    margin-left: 8/75rem;
  }
  &-planA{
    padding: .05333333rem .16rem;
    border-radius: .53333333rem;
    background-color: #f6f6f6;
    p{
      color: var(---sui_color_gray_dark1, #000);
    }
    .sui_icon_more_right_12px_2{
      color: var(---sui_color_gray_dark1, #000);
    }
    .icon {
    margin-left: .053333333rem;
  }
  }
}
.link-check-mysize__img {
  width: 0.454rem;
  height: 0.454rem;
  margin-right: 0.04rem;
  vertical-align: bottom;
  cursor: pointer;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

/* 修复 ios 第三方尺码层级不生效 */
html.uclw_widget_open {
    #uclw_wrapper {
        transform: translate3d(0, 0, 999px);
    }
}
.size-guide-entry-alone{
  background: none;
}
</style>
